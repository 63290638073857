@charset "utf-8";
@import "../ui/color";

.main-container {
    overflow-x: hidden;
    margin-top: 112px;
}

.col-wrap {
    height: 100%;

    .block{
        width: 100%;
        // padding-left: 10%;
        display: inline-flex;
        margin-bottom: 80px;
    }
    .block-left-home{
        width: 65%;
        font-size: 20px;
        font-weight:bold;
        font-family:open sans,sans-serif;
        background-color: rgb(242,173,46);
        padding: 10px 10px 10px 10px;

        .title{
            display: block;
            color:#0F0BE0;
        }

        .content{
            display: block;
            color:#000000;
        }
    }
    .block-right-home{
        width: 35%;
        margin-left: 30px;

        .s-btn{
            height: 40px;
            color: #fff;
            font-size: 22px;
            font-family: 'Open Sans',sans-serif;
            text-align: center;
            box-shadow: 0 1px 4px rgba(0,0,0,.6);
            margin-bottom: 15px;
            border: 2px solid #fff;
        }

        .yellow{
            background: linear-gradient( rgb(246,211,146), rgb(242,173,46));
            background: -ms-linear-gradient( rgb(246,211,146), rgb(242,173,46));
            background: -webkit-linear-gradient( rgb(246,211,146), rgb(242,173,46));
            background: -moz-linear-gradient( rgb(246,211,146), rgb(242,173,46));
        }

        .green{
            background: linear-gradient( rgb(156,198,120), rgb(98,170,35));
            background: -ms-linear-gradient( rgb(156,198,120), rgb(98,170,35));
            background: -webkit-linear-gradient( rgb(156,198,120), rgb(98,170,35));
            background: -moz-linear-gradient( rgb(156,198,120), rgb(98,170,35));
        }

        .blue{
            background: linear-gradient(rgb(179,229,229), rgb(0,136,203));
            background: -ms-linear-gradient(rgb(179,229,229), rgb(0,136,203));
            background: -webkit-linear-gradient(rgb(179,229,229), rgb(0,136,203));
            background: -moz-linear-gradient( rgb(179,229,229), rgb(0,136,203));
        }
    }
}

#container{
	width:840px;
	height:478px;
	position:relative;
	overflow:hidden;
    border-radius:10px;
    border: #ccc 1px solid;
    margin: 100px 0px 80px 70px;
}
.item-a{
    position:absolute;

    img{
        width: 840px;
    }  
}
 
#tabs{
	position:absolute;
	right:30px;
	bottom:20px;
}
.tab{
	float:left;
	margin-right:10px;
	width:6px;
	height:6px;
	border:3px solid rgba(184,178,182,.8);
	border-radius:50%;
	background:#989196;
	cursor:pointer;
}
.active{
	background:#fff;
	border-color:#999497;
}
.btn{
	position:absolute;
	top:50%;
	margin-top:-35px;
	width:40px;
	height:70px;
	color:#d6d3d5;
	font-size:36px;
	line-height:70px;
	text-align:center;
	cursor:pointer;
}
.btn:hover{
	background:rgba(127,120,125,0.8);
}
.prev{
	position:absolute;
	left:0;
	border-radius:0 5px 5px 0;	
}
.next{
	position:absolute;
	right:0;
	border-radius:5px 0 0 5px;
}